html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  /* min-height: 100%; */
  font-size: 0.875rem;
}

a {
  user-select: none;
}

a:focus {
  outline: 0 !important;
}

a:hover {
  text-decoration: none !important;
}

.main {
  height: 100%;
}

.page {
  padding: 0 16px;
}

.full {
  width: 100% !important;
  height: 100% !important;
}

.fullMedia {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.halfHeight {
  height: 50%;
}

.overlay {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.horizontalScroll {
  transform: translateZ(0);
  width: calc(100vw - 16px);
  overflow-x: auto;
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap !important;
}

.brand {
  font-family: 'Poiret One' !important;
  user-select: none;
}

.diamond {
  background-image: url(./images/diamond.png);
  background-repeat: no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-right: 5px;
}

.coin {
  background-image: url(./images/coin.png);
  background-repeat: no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-right: 5px;
}

.toy {
  display: block;
  background-image: url(./images/toy.png);
  background-repeat: no-repeat;
  background-size: 27px;
  background-position: center;
  width: 20px;
  height: 20px;
}

.logoMedium {
  display: block;
  background-image: url(./images/logo.png);
  background-repeat: no-repeat;
  background-size: 190.5px 81px;
  background-position: center;
  width: 190.5px;
  height: 81px;
}

.logo {
  display: block;
  background-image: url(./images/logo.png);
  background-repeat: no-repeat;
  background-size: 127px 54px;
  background-position: center;
  width: 127px;
  height: 54px;
}

@media only screen and (min-resolution: 2dppx), (-webkit-min-device-pixel-ratio: 2) {
  .diamond {
    background-image: url(./images/diamond@2x.png);
  }

  .coin {
    background-image: url(./images/coin@2x.png);
  }

  .toy {
    background-image: url(./images/toy@2x.png);
  }

  .logo {
    background-image: url(./images/logo@2x.png);
  }

  .logoMedium {
    background-image: url(./images/logo@2x.png);
  }
}

.mono {
  font-family: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas,
    monospace !important;
  overflow-wrap: break-word;
  line-height: 20px !important;
}

.avatar {
  width: 32px !important;
  height: 32px !important;
}

.avatar44 {
  width: 44px !important;
  height: 44px !important;
}

.avatar50 {
  width: 50px !important;
  height: 50px !important;
}

.avatar55 {
  width: 55px !important;
  height: 55px !important;
  margin-right: 10px;
}

.avatar85 {
  width: 85px !important;
  height: 85px !important;
}

.avatar100 {
  width: 100px !important;
  height: 100px !important;
}

.selectableList > li {
  margin-bottom: 12px;
  border-radius: 4px;
}

.transparentTabs {
  background-color: transparent !important;
}

.transparentTabs .MuiTab-textColorPrimary {
  color: #fff !important;
}

.transparentTabs .Mui-selected {
  color: #efe33a !important;
}

.transparentTabs .MuiTabs-indicator {
  display: none !important;
}

.giftDrawer .MuiBackdrop-root {
  background-color: transparent !important;
}

.gradientButton {
  filter: blur(5px);
  margin: 0px -5px;
}

.gradientButtonWrapper button {
  border-color: #fff;
}

.gradientButtonSmall {
  filter: blur(2px);
  margin: 0px -2px;
  border-radius: 5px;
}

.gradientButtonSmallWrapper button {
  border-color: #fff;
}

.gradientChip {
  height: 35px;
  filter: blur(2px);
  margin: 0px -2px;
  border-radius: 15px;
}

.gradientChipWrapper {
  height: 34px;
}

.gradientChipWrapper button {
  border-color: #fff;
  border-radius: 15px;
}

.outlinedIconButton {
  z-index: 1;
  padding: 8px !important;
  position: relative !important;
  margin-left: 10px !important;
}

.outlinedIconButton::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  border-radius: 45px;
  filter: blur(3px);
}

.chip {
  position: relative;
  min-width: 28px !important;
  padding: 3px 10px !important;
}

.chip::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: 16px;
  filter: blur(3px);
}

.smallChip {
  position: relative;
  padding: 3px 0px !important;
}

.smallChip::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: -1;
  border-radius: 16px;
  filter: blur(2px);
}

.roundInputButton {
  margin-right: -10px !important;
  border-radius: 30px !important;
}

.messagePic {
  height: 150px;
  max-width: 80vw;
  object-fit: cover;
}

.giftPic {
  width: 80px;
  height: 80px;
}

.mirror {
  transform: rotateY(180deg);
}

.blur video {
  filter: blur(30px) grayscale(50%);
}

#player > *:not(:last-child) {
  display: none;
}

#player canvas {
  position: relative !important;
  top: unset !important;
  left: unset !important;
  transform: rotateY(180deg);
}

#player video {
  transform: rotateY(180deg);
}

#camera video {
  border: 1px solid #000;
}

.gradientBg {
  position: relative;
}

.gradientBg::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  z-index: -1;
  border-radius: 8px;
  filter: blur(9px);
}

.gradientBorder {
  position: relative;
}

.gradientBorder::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  border-radius: 2.5px;
  filter: blur(3px);
}

.z2 {
  z-index: 2 !important;
}

.oneLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coverflow {
  padding: 0 30px;
}

.coverflow > .react-swipeable-view-container > div {
  padding: 0 10px;
}

.giftMessage {
  opacity: 0.7;
}

.giftMessage::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  border-radius: 5px;
  filter: blur(3px);
  opacity: 0.8;
}

.giftMessageCombo {
  font-style: italic;
  font-weight: bold;
}

.giftMessageImg {
  width: 80px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(0px, -50%);
}

.arrow {
  transition: transform 0.3s !important;
}

.arrow.up {
  transform: rotate(0deg);
}

.arrow.down {
  transform: rotate(180deg);
}

.bold {
  font-weight: bold !important;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.safeBottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.resetPasswordLink {
  margin: 10px 0 30px 0;
}

.phoneInput fieldset {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.countryCodeInput fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.audioPlay {
  width: 24px;
  height: 24px;
}

.audioPlay svg {
  font-size: 12px;
}

.crownFirst {
  position: absolute !important;
  width: 47px !important;
  height: 60px !important;
  left: -8px !important;
  bottom: -8px !important;
}

.crownSecond {
  position: absolute !important;
  width: 44px !important;
  height: 58px !important;
  left: -6px !important;
  bottom: -8px !important;
}

.crownThird {
  position: absolute !important;
  width: 40px !important;
  height: 50px !important;
  left: -5px !important;
  bottom: -6px !important;
}

.noBgColor {
  background-color: transparent !important;
}

.noSelect {
  user-select: none;
}

.followButton {
  padding: 2px 4px !important;
  min-width: unset !important;
  margin: 0 5px !important;
}

.minContentHeight {
  min-height: min-content;
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.pswp__counter {
  display: none;
}

.pswp__img--placeholder--blank {
  display: none;
}
