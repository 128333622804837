.unlockButton {
  height: 43px;
  filter: blur(5px);
  border-radius: 40px;
}

.unlockButtonWrapper {
  height: 43px;
  width: 200px;
}

.unlockButtonWrapper button {
  border-color: #fff;
  border-radius: 40px;
}

.shrinkParagraph {
  line-height: 20px !important;
  max-height: 40px !important;
  overflow: hidden;
}

.storyCard {
  user-select: none;
}

.storyMedia {
  width: 100vw;
  height: 100vw;
}

.storyActions {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 0;
}

.storySliderActions {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: -46px;
}

.storyActionButton {
  padding: 6px !important;
}

.storyTextSeparator {
  margin: 0 3px !important;
}

.storyContent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.videoDuration {
  mix-blend-mode: exclusion;
}

.videoPlay {
  font-size: 6rem !important;
  opacity: 0.8 !important;
}
